//
// morris.scss
//

.morris-charts {
    text {
        fill: var(--#{$prefix}secondary-color);
        font-family: $font-family-base !important;
    }
}
.morris-hover {
    position: absolute;
    z-index: 10;

    &.morris-default-style {
        font-size: 12px;
        text-align: center;
        border-radius: 5px;
        padding: 10px 12px;
        background: rgba($gray-100, 0.8);
        color: $dark;
        border: 2px solid $gray-200;
        font-family: $font-family-base;

        .morris-hover-row-label {
            font-weight: bold;
            margin: 0.25em 0;
        }

        .morris-hover-point {
            white-space: nowrap;
            margin: 0.1em 0;
            color: $white;
        }
    }
}

.workloed-chart{
    height: 273px !important;
}
.project-budget-detail-chart{
    height: 265px !important;
}

.project-budget-chart{
    height: 322px !important;
}
