// 
// Widgets.scss
// 


/*== Project Lonch ==*/

.icon-info {
    i{
      font-size: 36px;
    }
  }
  .live-tile {
    width: 100%;
    height: 120px;
    margin: 0;
    // border: 2px solid rgba($light, .12);
    border-radius: 5px;
    background-color: var(--#{$prefix}tertiary-bg);
    align-self: center;
  }
  
  
  /*== todo list ==*/
  
  .todo-list{
    .todo-box{
      .remove{
        position: absolute;
        right: 20px;
        z-index: 10;
        font-size: 18px;
        margin-top: 6px;
        color: $gray-500;
        padding: 0;
      }

      .todo-task{
        padding: 4px 0;
        label {
          display: inline-block;
          margin-bottom: .5rem;
        }
        .ckbox {
          font-weight: normal;
          position: relative;
          display: block;
          line-height: 24px;
         
          span {
            padding-left: 16px;
            &:before {
              content: '';
              width: 20px;
              height: 20px;
              background-color:  transparent;
              border: 2px solid var(--#{$prefix}border-color);
              top: 2px;
              left: 0;
              border-radius: 5px;
              position: absolute;
            }
            &:after {
              top: 1px;
              left: 0;
              width: 20px;
              height: 20px;
              content: "\F012C";
              font: normal normal normal 24px/1 "Material Design Icons";
              font-size: 14px;
              text-align: center;
              color: $gray-600;
              background-color: transparent;
              line-height: 20px;
              display: none;
              border-radius: 5px;
              position: absolute;
            }
          }
          input[type='checkbox'] {
            opacity: 0;
            margin: 0 5px 0 0;
          }
          input[type='checkbox']:checked + span:after {
            display: block;
          }
        }
        input:checked + span {
          text-decoration: line-through;
        }
      }
    }
    .form-control{
      color: $dark;
      background: $gray-300;
      border: 1px solid transparent;
      &:focus {
        border-color: transparent;
        background: $gray-300;
        box-shadow: none;
      }
      
    }
  }
  
  
  

/*== Dashboard-2 ==*/

.jvectormap-zoomout,
.jvectormap-zoomin{
  display: none;
}

#animating-donut {
          filter: drop-shadow( 0px 2px 1px $gray-300 ); 
}

.income h1{
  font-weight: 300;
}

.overview-content i{
  width: 44px;
  height: 44px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border: 2px solid;
  border-radius: 50%;
  font-size: 20px;
}

@media (max-width: 1366px) {
  .overview {    
    .overview-content {
      i {
        width: 36px;
        height: 36px;
        line-height: 34px;
        font-size: 16px;
      }
    }
    h4{
      font-size: 18px !important;
    }
  }

  .live-tile {
    height: 150px;
  }
  .knob-prosess-btn{
    display: none;
  }
  .income{
    h1{
      font-size: 30px;
    }
  }
}