// 
// Page-title
// 

.page-title-box {
    padding-bottom: $grid-gutter-width;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    @media (max-width: 575.98px) {
        .page-title-right{
            display: none;
        }
    }
}