// 
// Extras pages.scss
//


// pricing


.pricingTable{
    text-align: center;
    margin: 16px 0;
    .pricing-icon {
      i{
        width: 80px;
        height: 80px;
        text-align: center;
        line-height: 70px;
        font-size: 28px;
        border: 6px solid $card-bg;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 0.15px var(--#{$prefix}tertiary-bg);
        transition: all 300ms linear 0ms;
      }
    }
    .amount{
      display: block;
      font-size: 36px;
      font-weight: 500;
      color: var(--#{$prefix}emphasis-color);
      padding: 12px 0 6px;
      margin: 0;
    }

  }
  

/*--accordion--*/

.accordion {
  .card-header{
    background-color: $gray-200;
    padding: 0 12px;
  } 
}
