
//
//timeline.scss
//

.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

#cd-timeline {
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 2em 0;
  position: relative;
  &::before {
    border-left: 3px solid var(--#{$prefix}border-color);
    content: '';
    height: 100%;
    left: 18px;
    position: absolute;
    top: 0;
    width: 3px;
  }
}

@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-bottom: 3em;
    margin-top: 3em;
    &::before {
      left: 50%;
      margin-left: -2px;
    }
  }
}

.cd-timeline-block {
  margin: 2em 0;
  position: relative;
  &:after {
    clear: both;
    content: "";
    display: table;
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}


@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  
}
.cd-timeline-img {
  position: absolute;
  top: 11px;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 27px;
  font-size: 20px;
  color: $white;
  background-color: $primary;
  border: 5px solid $card-bg;

  i {
    margin-left: 1px;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 50px;
    height: 50px;
    line-height: 40px;
    left: 50%;
    top: 0;
    margin-left: -24px;
    transform: translateZ(0);
    backface-visibility: hidden;
  }
}

.cd-timeline-content {
  border-radius: 5px;
  border: 2px solid var(--#{$prefix}border-color);
  margin-left: 60px;
  padding: 1em;
  position: relative;
  background-color: var(--#{$prefix}tertiary-bg);

  &:after {
    clear: both;
    content: "";
    display: table;
  }
  p{
    color: var(--#{$prefix}secondary-color);
  }
  h2 {
    margin-top: 0;
  }
  .cd-read-more {
    background: $primary;
    border-radius: 0.25em;
    color:$white;
    float: right;
    font-size: 14px;
    padding: .8em 1em;
  }
  .cd-date {
    display: inline-block;
    font-size: 14px;
  }
  .left-number{
    font-size: 36px;
    font-weight: 300;
    color: $gray-600;
  }
  h3 {
    font-size: 16px;
    margin: 0 0 10px 0;
    border-radius: 5px;  
  }
}

.cd-timeline-content {
  .cd-date {
    float: left;
    padding: .8em 0;
    opacity: .7;
  }

  &:before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 12px solid transparent;
    border-right: 12px solid var(--#{$prefix}border-color);
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
    text-align: right;
    &:before {
      top: 12px;
      left: 100%;
      border-color: transparent;
      border-left-color: var(--#{$prefix}border-color);
    }

    .cd-read-more {
      float: left;
    }

    .cd-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 2px;
      text-align: left;
    }
  }
  

  .cd-timeline-block:nth-child(even) {
    .cd-timeline-content {
      float: right; 
      text-align: left;
      &:before {
        top: 12px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: var(--#{$prefix}border-color);
      }

      .cd-read-more {
        float: right;
      }

      .cd-date {
        left: auto;
        right: 122%;
        text-align: right;
      }
    }
  }

}
